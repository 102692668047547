
import { Vue, Component } from "vue-property-decorator";
import { mapMutations, mapGetters } from "vuex";
import { ResponseDataStringArray as rdsa, FilterFormSAT } from "@/interfaces";
import * as types from "@/store/types";
import { TOKEN } from "@/services/auth/auth-service";
@Component({
    components: {
        FilterFormPanelBase: () =>
            import("@/components/layout/FilterFormPanelBase.vue"),
        FilterForm: () => import("@/pages/components/forms/FilterFormSAT.vue"),
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        PlotlyChart: () => import("@/components/charts/PlotlyChart.vue"),
        CalendarInput: () => import("@/components/layout/CalendarInput.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER
        })
    }
})
export default class Saving extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    filterForm: FilterFormSAT = {
        socio: "",
        company: "",
        client: "",
        installation: "",
        caja: "",
        suiche: false
    };
    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }
    mounted() {
        // eslint-disable-next-line
        this.fetchElementoInit();
        this.fetchFormularyInit();
    }
    role = Vue.prototype.$user.get().role;
    userIdentifier?: number;
    socios: string[] = [];
    companies: string[] = [];
    company = "";
    clients: string[] = [];
    client = "";
    installations: string[] = [];
    installation = "";
    rules = {
        required: value => !!value || "Requerido",
        counter: value => value.length <= 6 || "Maximo 6 dígitos",
        days: value => !!Number(value) || "No es un número"
    };
    cache: string[] = [];
    Estandares = [0, 1, 2, 3];
    Ncaja = 0;
    socio = "";
    Nestanda = 0;
    Tip = "";
    timeZones: string[] = [];
    timeZone = "Europe/Madrid";
    timeLog = 0;
    phone = "";
    descripcion = "";
    Faltasis = "";
    Fbaja = "";
    Title = "";
    filtered = false;
    dialogDelete = false;
    newBoxBool = false;
    Ncajaele = 0;
    cajaEles: string[] = [];
    cajaEle = "";
    Npadre = 0;
    realEles: string[] = [];
    realEle: string | undefined = "0. Raiz";
    padreEles: string[] = [];
    padreEle: string | undefined = "0. Raiz";
    eleTips: string[] = [];
    eleTip: string | undefined = "";
    familias: string[] = [];
    familia: string | undefined = "";
    famTips: string[] = [];
    famTip: string | undefined = "";
    servTips: string[] = [];
    servTip: string | undefined = "";
    alaTips: string[] = [];
    alaTip: any = "";
    nalaTips = { 22: "A", 23: "B", 24: "C", 25: "D", 26: "E" };
    nalaTips4 = { 20: "E" };
    Nalatipo: string[] = [];
    Nprotoco = 0;
    Melees = "";
    Tdirprot = "";
    nelementoE = 0;
    Nfallos = 0;
    Info1 = "";
    Info2 = "";
    PToler = 25;
    cabecera = [
        {
            text: "Tubi_es",
            align: "start",
            value: "Tubi_es"
        },
        {
            text: "Tcaja",
            align: "start",
            value: "Tcaja"
        },
        {
            text: "Tlugar",
            align: "start",
            value: "Tlugar"
        },
        {
            text: "Mele_es",
            align: "start",
            value: "Mele_es"
        },
        {
            text: "Tele_es",
            align: "start",
            value: "Tele_es"
        },
        {
            text: "Tip",
            align: "start",
            value: "Tip"
        },
        {
            text: "Faltasis",
            align: "start",
            value: "Faltasis"
        },
        {
            text: "Fmodific",
            align: "start",
            value: "Fmodific"
        }
    ];
    elementos: any[] = [];
    cabAla = [
        {
            text: "Nalatipo",
            align: "start",
            value: "Nalatipo"
        },
        {
            text: "Talatipo",
            align: "start",
            value: "Talatipo"
        },
        {
            text: "activa",
            align: "start",
            value: "activa"
        },
        {
            text: "SMS",
            align: "start",
            value: "SMS"
        },
        {
            text: "Hi",
            align: "start",
            value: "Hi"
        },
        {
            text: "Lo",
            align: "start",
            value: "Lo"
        },
        {
            text: "",
            align: "start",
            value: "actions"
        }
    ];

    alarmas: any[] = [];

    grEditIndex = 0;
    editedAlarm = {
        Nalarma: 0,
        Nalatipo: 0,
        Talatipo: "",
        activa: true,
        SMS: true,
        Hi: 0,
        Lo: 0
    };

    dialog = false;
    addAlarmBool = false;
    formTitle = "";
    dialogDeleteAla = false;

    async getAlaEle() {
        try {
            const Response = await this.$api.getAlaEle<rdsa>(this.Ncajaele);
            if (Response.ok) {
                this.alarmas = Response.data;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    editGroup(item) {
        this.formTitle = "Editar alarma";
        this.grEditIndex = this.alarmas.indexOf(item);
        this.editedAlarm = Object.assign({}, item);
        this.alaTip = this.alaTips.find(
            ele => ele.split(" - ")[0] == this.editedAlarm.Nalatipo.toString()
        );
        this.dialog = true;
        this.addAlarmBool = false;
    }

    async confirmEditAlarm() {
        const JSOON = {
            Ncajaele: this.Ncajaele,
            Ncaja: this.Ncaja,
            Nalarma: this.editedAlarm.Nalarma,
            Nalatipo: this.alaTip.split(" - ")[0],
            activa: this.editedAlarm.activa,
            SMS: this.editedAlarm.SMS,
            Hi: this.editedAlarm.Hi,
            Lo: this.editedAlarm.Lo
        };
        try {
            const response = await this.$api.updateAla<rdsa>(JSOON);
            if (response.ok) {
                this.dialog = false;
                this.editedAlarm = {
                    Nalarma: 0,
                    Nalatipo: 0,
                    Talatipo: "",
                    activa: true,
                    SMS: true,
                    Hi: 0,
                    Lo: 0
                };
                this.grEditIndex = 0;
                this.alarmas = [];
                this.getAlaEle();
                this.addAlarmBool = false;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    addAlarm() {
        this.formTitle = "Añadir alarma";
        this.grEditIndex = 0;
        this.editedAlarm = {
            Nalarma: 0,
            Nalatipo: 0,
            Talatipo: "",
            activa: true,
            SMS: true,
            Hi: 0,
            Lo: 0
        };
        this.alaTip = "Seleccione";
        this.dialog = true;
        this.addAlarmBool = true;
    }

    async confirmAddAlarm() {
        const JSOON = {
            Ncajaele: this.Ncajaele,
            Ncaja: this.Ncaja,
            Nalatipo: this.alaTip.split(" - ")[0],
            activa: this.editedAlarm.activa,
            SMS: this.editedAlarm.SMS,
            Hi: this.editedAlarm.Hi,
            Lo: this.editedAlarm.Lo
        };
        try {
            const response = await this.$api.addAla<rdsa>(JSOON);
            if (response.ok) {
                this.dialog = false;
                this.editedAlarm = {
                    Nalarma: 0,
                    Nalatipo: 0,
                    Talatipo: "",
                    activa: true,
                    SMS: true,
                    Hi: 0,
                    Lo: 0
                };
                this.grEditIndex = 0;
                this.alarmas = [];
                this.getAlaEle();
                this.addAlarmBool = false;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    delAlarm(item) {
        this.formTitle = "Añadir grupo";
        this.grEditIndex = this.alarmas.indexOf(item);
        this.editedAlarm = Object.assign({}, item);
        this.dialogDeleteAla = true;
        this.addAlarmBool = false;
    }

    async confirmDelAlarm() {
        const JSOON = {
            Nalarma: this.editedAlarm.Nalarma,
            Ncaja: this.Ncaja
        };
        try {
            const response = await this.$api.delAla<rdsa>(
                // this.client.split(" - ")[0]
                JSOON
            );
            if (response.ok) {
                this.dialog = false;
                this.editedAlarm = {
                    Nalarma: 0,
                    Nalatipo: 0,
                    Talatipo: "",
                    activa: true,
                    SMS: true,
                    Hi: 0,
                    Lo: 0
                };
                this.grEditIndex = 0;
                this.alarmas = [];
                this.getAlaEle();
                this.addAlarmBool = false;
                this.dialogDeleteAla = false;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    cancelGroup() {
        this.dialog = false;
        this.dialogDeleteAla = false;
    }
    elementosInit: any[] = [];
    formulario: any[] = [];

    async fetchElementoInit() {
        try {
            const Response = await this.$api.getElementSATInit<rdsa>();
            if (Response.ok) {
                this.fetchFamTip();
                this.elementosInit = this.formatDate(Response.data);
                this.elementos = JSON.parse(JSON.stringify(this.elementosInit));
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async fetchFormularyInit() {
        try {
            const formularyResponse = await this.$api.getFormularyInit<rdsa>();
            if (formularyResponse.ok) {
                this.formulario = formularyResponse.data;
                this.formulario.forEach(i => {
                    this.socios.push(i["socio"]);
                });
                const aux: string[] = [];
                this.socios.forEach(function(item) {
                    if (aux.indexOf(item) < 0) {
                        aux.push(item);
                    }
                });
                this.socios = aux;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    get Nalatipos() {
        const aux: string[] = [];
        for (const x in this.nalaTips) {
            aux.push(this.nalaTips[x].toString());
        }
        return aux;
    }

    get Nalatipos4() {
        const aux: string[] = [];
        for (const x in this.nalaTips4) {
            aux.push(this.nalaTips4[x].toString());
        }
        return aux;
    }

    fetchCompany(NOreinicio = false) {
        const filtro = this.formulario.filter(item => item.socio == this.socio);
        const aux: string[] = [];
        const aux0 = JSON.parse(JSON.stringify(filtro));
        aux0.forEach(function(item) {
            if (aux.indexOf(item["company"]) < 0) {
                aux.push(item["company"]);
            }
        });
        this.companies = aux;
        if (!NOreinicio) {
            this.company = "Seleccione una empresa";
            this.client = "Seleccione un cliente";
            this.installation = "Seleccione un instalación";
            this.cajaEle = "Seleccione una caja";
        }
    }

    fetchClient(NOreinicio = false) {
        const filtro = this.formulario.filter(
            item => item.company == this.company
        );
        const aux: string[] = [];
        const aux0 = JSON.parse(JSON.stringify(filtro));
        aux0.forEach(function(item) {
            if (aux.indexOf(item["client"]) < 0) {
                aux.push(item["client"]);
            }
        });
        this.clients = aux;
        if (!NOreinicio) {
            this.client = "Seleccione un cliente";
            this.installation = "Seleccione un instalación";
            this.cajaEle = "Seleccione una caja";
        }
    }

    fetchInstallation(NOreinicio = true) {
        const filtro = this.formulario.filter(
            item => item.client == this.client
        );
        const aux: string[] = [];
        const aux0 = JSON.parse(JSON.stringify(filtro));
        aux0.forEach(function(item) {
            if (aux.indexOf(item["install"]) < 0) {
                aux.push(item["install"]);
            }
        });
        this.installations = aux;
        this.cajaEles = [];
        if (!NOreinicio) {
            this.installation = "Seleccione un instalación";
            this.cajaEle = "Seleccione una caja";
        }
    }

    async fetchCajaEle() {
        try {
            const Response = await this.$api.getBoxesEleSAT<rdsa>(
                this.installation.split(" - ")[0]
            );
            if (Response.ok) {
                this.cajaEles = [];
                Response.data.forEach(i => {
                    this.cajaEles.push(i["Caja"]);
                });
            }
            this.getRealEle();
            this.getPadreEle();
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async getRealEle() {
        try {
            const Response = await this.$api.getRealEle<rdsa>(
                this.installation.split(" - ")[0]
            );
            if (Response.ok) {
                this.realEles = ["0. Raiz"];
                Response.data.forEach(i => {
                    this.realEles.push(i["Tele_es"]);
                });
            }
            if (this.realEle != "0. Raiz") {
                this.realEle = this.realEles.find(
                    ele => ele.split(". ")[1] == this.realEle
                );
            }
            if (this.realEle == "") {
                this.realEle = "0. Raiz";
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async getPadreEle() {
        try {
            const Response = await this.$api.getPadreEle<rdsa>(
                this.installation.split(" - ")[0]
            );
            if (Response.ok) {
                this.padreEles = ["0. Raiz"];
                Response.data.forEach(i => {
                    this.padreEles.push(i["Mele_es"]);
                });
            }
            if (this.padreEle != "0. Raiz" && this.Npadre != 0) {
                this.padreEle = this.padreEles.find(
                    ele =>
                        ele.split(". ")[1].split(" - ")[0] ==
                        this.Npadre.toString()
                );
            } else {
                this.padreEle = "0. Raiz";
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async fetchFamTip() {
        try {
            const Response = await this.$api.getFamiTip<rdsa>();
            if (Response.ok) {
                this.eleTips = Response.data["Tip"];
                this.familias = Response.data["Fam"];
                this.famTips = Response.data["Tfam"];
                this.servTips = Response.data["Tserv"];
                this.nalaTips = Response.data["Tala"];
                this.nalaTips4 = Response.data["tala4"];
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async fetchAlaTip() {
        try {
            const Response = await this.$api.getAlaTip<rdsa>(
                parseInt(
                    typeof this.familia == "undefined"
                        ? ""
                        : this.familia.length == 0
                        ? ""
                        : this.familia.split(" - ")[0]
                )
            );
            if (Response.ok) {
                this.alaTips = Response.data["talaE"];
                const aux: string[] = [];
                for (const x in this.alaTips) {
                    aux.push(this.alaTips[x].toString());
                }
                this.alaTips = aux;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async fetchElemento() {
        const JSOON = {
            socio: this.filterForm.socio,
            company: this.filterForm.company,
            client: this.filterForm.client,
            installation: this.filterForm.installation,
            Tserie: this.filterForm.Tserie,
            Tcaja: this.filterForm.Tcaja,
            Tcodigo: this.filterForm.Tcodigo,
            Ncaja: parseInt(this.filterForm.caja.split(" - ")[0])
        };
        try {
            const Response = await this.$api.getElementsSAT<rdsa>(
                // this.client.split(" - ")[0]
                this.filterForm.suiche,
                JSOON
            );
            if (Response.ok) {
                // this.cajas = this.formatDate(Response.data);
                this.elementos = this.formatDate(Response.data);
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()];
    }

    fetchElementoLine(caja) {
        this.newBoxBool = false;
        function check(box) {
            return box["install"].split(" - ")[0] == caja.Ninstala;
        }
        const aux = this.formulario.find(check);
        this.familia = this.familias.find(
            ele => ele.split(" - ")[0] == caja.Nfamilia
        );
        this.famTip = this.famTips.find(
            ele => ele.split(" - ")[0] == caja.Nfamtipo
        );
        this.eleTip = this.eleTips.find(
            ele => ele.split(" - ")[0] == caja.Ntipo
        );
        this.servTip = this.servTips.find(
            ele => ele.split(" - ")[0] == caja.Nserviciotipo
        );
        this.Ncajaele = caja.Ncajaele;
        this.Ncaja = caja.Ncaja;
        this.installation = aux["install"];
        this.client = aux["client"];
        this.socio = aux["socio"];
        this.company = aux["company"];
        this.Title = "Modicación de " + caja.Mele_es;
        this.Melees = caja.Mele_es;
        this.nelementoE = caja.n_elemento;
        this.Nfallos = caja.Nfallos;
        this.Info1 = caja.Tinfo1;
        this.Info2 = caja.Tinfo2;
        this.Tdirprot = caja.Tdirprot;
        this.Faltasis = caja.Faltasis;
        this.Nprotoco = caja.Nprotoco;
        if (caja.Tcaja != "") {
            this.cajaEle = caja.Tcaja;
        } else {
            this.realEle = "0. Raiz";
        }
        this.Tip = caja.Tip;
        this.realEle = caja.Tele_es;
        this.PToler = caja.PToler;
        this.Npadre = caja.Npadre;
        this.padreEle = caja.Npadre.toString();
        this.Nalatipo = [];
        for (const x of caja.Nalatipo) {
            this.Nalatipo.push(this.nalaTips[x]);
        }
        this.alarmas = [];
        this.fetchCompany(true);
        this.fetchClient(true);
        this.fetchInstallation(true);
        this.fetchCajaEle();
        this.fetchAlaTip();
        this.getAlaEle();
        // this.getRealEle();
    }

    formatDate(data) {
        for (const pl of data) {
            pl.Faltasis = new Date(pl.Faltasis).toISOString().split("T")[0];
            pl.Fmodific = new Date(pl.Fmodific).toISOString().split("T")[0];
        }
        return data;
    }

    goTo() {
        window.location.replace("/#/v2/form");
    }

    enDelDial() {
        this.dialogDelete = true;
    }

    closeDelete() {
        this.dialogDelete = false;
        // this.$nextTick(() => {
        //     this.editedItem = Object.assign({}, this.defaultItem);
        //     this.editedIndex = -1;
        // });
    }

    async deleteBox() {
        const JSOON = {
            Ncajaele: this.Ncajaele,
            Ncaja: this.cajaEle
        };
        /*Cuando se borra un proyecto, tambien eliminarlo de la lista de proyectos*/
        try {
            const deleteResponse = await this.$api.deleteElementSAT<rdsa>(
                this.Ncajaele
            );
            if (deleteResponse.ok) {
                this.dialogDelete = false;
                this.Title = "";
                this.showError(
                    "La caja " + this.Melees + " se ha eliminado correctamente"
                );
                this.elementos = [];
                if (this.filtered) {
                    this.fetchElemento();
                } else {
                    this.fetchElementoInit();
                }
                this.Ncaja = 0;
                this.installation = "";
                this.client = "";
                this.socio = "";
                this.company = "";
                this.Nestanda = 0;
                this.timeZone = "Europe/Madrid";
                this.timeLog = 0;
                this.phone = "";
                this.Faltasis = "";
                this.Fbaja = "";
                this.Tip = "";
                this.alarmas = [];
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async editElement() {
        const JSOON = {
            Ncajaele: this.Ncajaele,
            Nsocio: this.socio,
            Nempresa: this.company,
            Ncliente: this.client,
            Ninstala: this.installation,
            Nelement: this.realEle,
            Nfamilia: this.familia,
            nelemento: this.nelementoE,
            Nfamtipo: this.famTip,
            Tdirprot: this.Tdirprot,
            Ncaja: this.cajaEle,
            Nserviciotipo: this.servTip,
            Npadre:
                typeof this.padreEle == "undefined"
                    ? ""
                    : this.padreEle.length == 0
                    ? ""
                    : this.padreEle.split(". ")[1],
            Melees: this.Melees,
            Tip: this.Tip,
            Ntipo: this.eleTip,
            Nprotoco: this.Nprotoco,
            Nfallos: this.Nfallos,
            Tinfo1: this.Info1,
            Tinfo2: this.Info2,
            Faltasis: this.Faltasis,
            PToler: this.PToler,
            Nalatipo: this.Nalatipo
        };
        try {
            const deleteResponse = await this.$api.modifyElementSAT<rdsa>(
                JSOON
            );
            const a = true;
            if (deleteResponse.ok) {
                this.dialogDelete = false;
                this.Title = "";
                this.showError(
                    "La caja " + this.Melees + " se ha modificado correctamente"
                );
                this.elementos = [];
                if (this.filtered) {
                    this.fetchElemento();
                } else {
                    this.fetchElementoInit();
                }
                this.Ncaja = 0;
                this.installation = "";
                this.client = "";
                this.socio = "";
                this.company = "";
                this.Nestanda = 0;
                this.timeZone = "Europe/Madrid";
                this.timeLog = 0;
                this.phone = "";
                this.Faltasis = "";
                this.Fbaja = "";
                this.Tip = "";
                this.alarmas = [];
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async addElement() {
        const JSOON = {
            Nsocio: this.socio,
            Nempresa: this.company,
            Ncliente: this.client,
            Ninstala: this.installation,
            Nelement: this.realEle,
            Nfamilia: this.familia,
            nelemento: this.nelementoE,
            Nfamtipo: this.famTip,
            Tdirprot: this.Tdirprot,
            Ncaja: this.cajaEle,
            Nserviciotipo: this.servTip,
            Npadre:
                typeof this.padreEle == "undefined"
                    ? ""
                    : this.padreEle.length == 0
                    ? ""
                    : this.padreEle.split(". ")[1],
            Melees: this.Melees,
            Tip: this.Tip,
            Ntipo: this.eleTip,
            Nprotoco: this.Nprotoco,
            Nfallos: this.Nfallos,
            Tinfo1: this.Info1,
            Tinfo2: this.Info2,
            Faltasis: this.Faltasis,
            PToler: this.PToler,
            Nalatipo: this.Nalatipo
        };
        try {
            const deleteResponse = await this.$api.addElementSAT<rdsa>(JSOON);
            const a = true;
            if (deleteResponse.ok) {
                this.dialogDelete = false;
                this.Title = "";
                this.showError(
                    "El elemento " + this.Melees + " se ha creado correctamente"
                );
                this.elementos = [];
                if (this.filtered) {
                    this.fetchElemento();
                } else {
                    this.fetchElementoInit();
                }
                this.Ncaja = 0;
                this.installation = "";
                this.client = "";
                this.socio = "";
                this.company = "";
                this.Nestanda = 0;
                this.timeZone = "";
                this.timeLog = 0;
                this.phone = "";
                this.Faltasis = "";
                this.Fbaja = "";
                this.Tip = "";
                this.alarmas = [];
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    onFilterUpdated() {
        if (Vue.$cookies.isKey(TOKEN)) {
            //logica de los periodType
            this.filtered = true;
            this.Title = "";
            this.fetchElemento();
        } else {
            this.$router.push("/signin");
        }
    }

    onFilterReset() {
        if (Vue.$cookies.isKey(TOKEN)) {
            //logica de los periodType
            this.dialogDelete = false;
            this.filtered = false;
            this.elementos = [];
            this.Title = "";
            this.Ncaja = 0;
            this.installation = "";
            this.client = "";
            this.socio = "";
            this.company = "";
            this.Nestanda = 0;
            this.timeZone = "Europe/Madrid";
            this.timeLog = 0;
            this.phone = "";
            this.Faltasis = "";
            this.Fbaja = "";
            this.Tip = "";
            this.alarmas = [];
            this.fetchElementoInit();
        } else {
            this.$router.push("/signin");
        }
    }

    newBox() {
        this.dialogDelete = false;
        this.newBoxBool = true;
        this.Title = "Nuevo elemento";
        this.Ncaja = 0;
        this.socio = "Seleccione un socio";
        this.company = "Seleccione una empresa";
        this.client = "Seleccione un cliente";
        this.installation = "Seleccione una instalación";
        this.cajaEle = "Seleccione una caja";
        this.cajaEles = [];
        this.Nestanda = 0;
        this.timeZone = "Europe/Madrid";
        this.timeLog = 0;
        this.phone = "";
        this.Faltasis = "";
        this.Fbaja = "";
        this.Tip = "";
    }
}
